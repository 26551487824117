<template src="./CreateMassManagementPage.html"></template>
<style scoped lang="scss" src="./CreateMassManagementPage.scss"></style>

<script>
import {OrderType} from "../../../../../constants";
import {OrderCreateMixin} from "../../../../../mixins";

const CreateMassManagementForm = () => import('@/components/form/mass-management-form/MassManagementForm')

export default {
  name: 'SearchPage',
  mixins: [OrderCreateMixin],
  components: { CreateMassManagementForm },
  data: function () {
    return {
      orderType: OrderType.Mass
    }
  },
  methods: {
    handleOnFormCancel() {
      this.$router.push({ name: 'routes.massManagement' })
    }
  }
}
</script>
